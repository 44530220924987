import { Link } from 'react-router-dom';

const Downloads = () => {

    return (
        <>
            <div className="downloadtitle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="page-tit">
                                <h1>&nbsp;</h1>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                            <div className="breadcrumb-page">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Download</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container media-sec mt-20">
                {/* <div className="text-center m-4">
                    <Link style={{ backgroundColor: '#1c0732', color: 'white', border: 'none', borderRadius: '5px', fontWeight: 'bold', padding: '10px' }} to="/annualreturn">
                        Annual Return
                    </Link>
                </div> */}
                <div className="row no-gutters">
                    <div className="col-md-12 col-sm-12 col-xs-12 m-2">
                        <ul className="list-group">
                            <a href="/download/brochure/iPUMPNET-Brochure.pdf" target="_blank">
                                <li className="list-group-item">
                                    <h5>
                                        iPUMPNET Brochure
                                    </h5>
                                </li>
                            </a>
                        </ul>
                        <br></br><br></br>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Downloads;