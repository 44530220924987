import $ from "jquery";
import React, { useEffect } from "react";

const Team = () => {
    useEffect(() => {
        $(".team-item-click-area").on("click", function () {
            if ($(this).parent().hasClass("is--active")) {
                $(this).parent().removeClass("is--active");
            } else {
                $(".team-item").removeClass("is--active");
                $(this).parent().addClass("is--active");
            }
        });

        $(".team-item-description-close").on("click", function () {
            $(".team-item").removeClass("is--active");
            console.log("clicked!");
        });
    }, []);

    useEffect(() => {
        $(".team-item-click-area-main").on("click", function () {
            if ($(this).parent().hasClass("is--active")) {
                $(this).parent().removeClass("is--active");
            } else {
                $(".team-item-main").removeClass("is--active");
                $(this).parent().addClass("is--active");
            }
        });

        $(".team-item-description-close-main").on("click", function () {
            $(".team-item-main").removeClass("is--active");
            //console.log('clicked!')
        });
    }, []);

    return (
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-3 mx-auto">
                        <div className="team-grid">
                            <div className="team-item-main">
                                <div className="team-item-click-area-main">
                                    <p>
                                        <img
                                            className="team-item-photo"
                                            src="images/himadri.webp"
                                            alt=""
                                        />
                                    </p>
                                    <div className="team-item-brief">
                                        <p className="team-item-name">
                                            <strong>Himadri Sen </strong>
                                        </p>
                                        <p className="team-item-title">Mentor</p>
                                        <div className="team-item-arrow">
                                            <i className="fal fa-angle-down"></i>
                                        </div>
                                        <div className="team-item-brief-arrow"></div>
                                    </div>
                                </div>
                                <div className="team-item-description-main">
                                    <p>
                                        Himadri Sen is the founder of Pumpsense Fluid Engineering
                                        Private Limited, a company that specializes in providing
                                        training and consulting services for centrifugal pump users
                                        and manufacturers. He holds a Bachelor's degree in
                                        Mechanical Engineering from the Birla Institute of
                                        Technology and a postgraduate diploma in management from the
                                        Indian Institute of Management in Kolkata. He began his
                                        career in the pump industry in 1974 and worked for Mather &
                                        Platt Limited for 19 years, handling a range of tasks
                                        including sales, product development, and strategic
                                        planning. Pumpsense has a global customer base and is known
                                        for its expertise in the manufacturing of split case and
                                        large end suction pumps for a variety of applications,
                                        including construction, industrial services, and marine fire
                                        fighting. The company is also highly regarded for its pump
                                        diagnostic, retrofit, upgrade, and selection services. Mr.
                                        Sen is passionate about centrifugal pump training and has
                                        taught pump users and manufacturers globally through
                                        training programs and workshops.
                                    </p>
                                    <div className="team-item-description-close-main">
                                        <i className="fal fa-times-circle"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="team-client">
                <div className="container">
                    <div className="heading-title text-center">
                        <h2>Meet our team</h2>
                    </div>
                </div>
            </div>

            <div className="team-aall">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="team-grid">
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/anil.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Anil Kumar Sethi</strong>
                                            </p>
                                            <p className="team-item-title">Founder Chairman</p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Anil Kumar Sethi is a highly experienced and visionary
                                            leader, who is the founder and director of Pump Academy
                                            Private Limited. He has a career spanning over 40 years in
                                            the fields of engineering and infrastructure development.
                                            With a strong background in entrepreneurship, he has
                                            successfully led the growth of his earlier company, taking
                                            it from a small contracting company to a prominent
                                            infrastructure development firm in India. Mr. Sethi has
                                            extensive experience in managing and executing projects in
                                            various sectors, such as water, power, sanitation, smart
                                            city development, metro rail, and other civil
                                            infrastructure. Furthermore, he has hands-on experience in
                                            operating and managing over 400 large and medium pumping
                                            stations across India. Additionally, he has experience in
                                            the field of renewable energy, energy-saving initiatives,
                                            and integrated water supply and management.
                                        </p>
                                        <strong>
                                            During his time as chairman in his previous company, he
                                            played a vital role as a lead member of prominent industry
                                            organizations and institutions
                                        </strong>
                                        <ul>
                                            <li>World Economic Forum</li>
                                            <li>Confederation of Indian Industry (CII)</li>
                                            <li>
                                                Federation of Indian Chambers of Commerce & Industry
                                                (FICCI)
                                            </li>
                                            <li>Bangalore Chamber of Commerce and Industry</li>
                                            <li>
                                                Steering Committees on Water Management and Urban
                                                Infrastructure
                                            </li>
                                        </ul>
                                        <p>Indicative list of Awards conferred:</p>
                                        <ul>
                                            <li>
                                                Entrepreneur of the Year Award 2009 for his contribution
                                                as a Business Leader Par Excellence by Enterprise Asia.
                                            </li>
                                            <li>Vikas Jyoti Award.</li>
                                            <li>India Quality Gold Star Award.</li>
                                            <li>The Pride of India Gold Star Award.</li>
                                            <li>
                                                Nominated and Empaneled as Eminent Citizen with the
                                                NREGA (Mahatma Gandhi National Rural Employment
                                                Guarantee Act) – 2010
                                            </li>
                                        </ul>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/deepak.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Deepak Sethi</strong>
                                            </p>
                                            <p className="team-item-title">Director - Strategy</p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Deepak Sethi is a co-founder and director of Pump Academy
                                            Private Limited, bringing 20 years of experience to the
                                            company. He is known for his innovative business
                                            strategies and professional approach, and has expertise in
                                            various areas such as marketing, planning, execution,
                                            operation and maintenance of contracts in various sectors
                                            like waste management, infrastructure, and real estate. He
                                            also has hands-on experience in operating and managing
                                            over 200 large and medium pumping stations across India.
                                            He has played a key role in building the organization's
                                            technological capabilities and envisions to make Pump
                                            Academy a global leader. Mr. Sethi holds an MBA from
                                            Deakin University in Australia.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo w-fit "
                                                src="images/Photo Rakesh.jpg"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Rakesh Kumar Jain</strong>
                                            </p>
                                            <p className="team-item-title">Non-Executive Director</p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Rakesh Kumar Jain is a dynamic financial professional with
                                            28 years of experience, holding qualifications in B.Com
                                            (Hons), FCA, FCS, and more. A Chartered Accountant and
                                            Company Secretary, he excels in income tax, accounting,
                                            audits, and compliance. Rakesh has led financial teams,
                                            managed complex structures, and liaised with regulatory
                                            authorities. As an Insolvency Professional and Registered
                                            Valuer, he drives growth through sound financial
                                            management, overseeing tax planning, audits, and financial
                                            reporting. He develops strategic financial plans,
                                            implements internal controls, and offers expert advice on
                                            complex financial matters to aid informed decision-making.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>{" "}
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="safari-height team-item-photo w-full lg:w-[87%] xs:h-[190px] sm:h-[200px] md:h-[220px] lg:h-[285px]"
                                                src="images/Suresh Sir Photo.jpg"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Suresh N </strong>
                                            </p>
                                            <p className="team-item-title">Technical Project Lead</p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            An Electronics Engineer with 40 years of experience, including a decade as Managing Director of a multinational company’s Indian operations, and 18 years in consultancy and software development across various technologies. Skilled in legacy technologies like Windows Applications and ASPX, databases such as MSSQL Server and Postgres, and modern cloud technologies including Angular, ReactJS, .NET Core, and SQL Server. Currently focused on IoT data collection, Edge computing, and cloud-based data analytics applications. Passionate about continuous learning, teamwork, and imparting knowledge, with a commitment to quality-driven software design. Aiming to further specialize in cloud computing, software architecture, and establishing in-house programming and web hosting capabilities.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="team-item">
                                <div className="team-item-click-area">
                                    <p><img className="team-item-photo" src="images/dileep.webp" alt="" /></p>
                                    <div className="team-item-brief">
                                        <p className="team-item-name"><strong>Dileep Setty</strong></p>
                                        <p className="team-item-title">Head - Hardware Engineering</p>
                                        <div className="team-item-arrow"><i className="fal fa-angle-down"></i></div>
                                        <div className="team-item-brief-arrow"></div>
                                    </div>
                                </div>
                                <div className="team-item-description">
                                    <p>Mr. Dileep Setty is leading the hardware development. He holds a degree in Electronics and Communications Engineering, and has 24 years of experience in industrial product design and development. He has a track record of working with leading semiconductor companies on high power and motion control technology projects in India and the US. He is experienced in concept research and development, and has a history of manufacturing energy-efficient products and solutions for the electric vehicle, specialized power, and motion control markets. He holds four patents in the areas of motion control, electric vehicle, and battery charging. In addition to his professional work, Mr. Setty also serves as a project advisor for IIT Chennai and IIT Hyderabad, where he helps in developing energy-efficient solutions.</p>
                                    <div className="team-item-description-close"><i className="fal fa-times-circle"></i></div>
                                </div>
                            </div> */}
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/amar.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Amar Muniraju</strong>
                                            </p>
                                            <p className="team-item-title">
                                                Lead - Embedded Engineering
                                            </p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Mr. Amar Muniraj is a Lead Embedded Engineer with a
                                            background in electronic and communication engineering
                                            from Visvesvaraya Technological University. He has 16
                                            years of experience in the field, focusing on embedded
                                            products and their design lifecycle. He has expertise in
                                            embedded system design, programming, and troubleshooting,
                                            with over 12 years of experience in embedded product and
                                            firmware development. He also has 4 years of experience in
                                            battery management system (BMS) integration programming.
                                            He holds an international patent in the field of embedded
                                            technology as a co-inventor.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/chander-mohan.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Chandra Mohan M S</strong>
                                            </p>
                                            <p className="team-item-title">Project Administrator </p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Chandra Mohan is a seasoned professional with 21 years of
                                            experience in project management, procurement and supply
                                            chain management, as well as business development in the
                                            infrastructure development sector. He has a proven track
                                            record in leading teams in various verticals, developing
                                            project baselines, monitoring and controlling projects
                                            with respect to cost, resource deployment, schedule
                                            over-runs and quality compliance. He is experienced in
                                            creating competitive proposals, developing new service
                                            offerings based on detailed and documented insights of
                                            market and client needs. He has expertise in managing and
                                            maintaining structured analysis of target markets, clients
                                            and documentation, providing market intelligence and
                                            feedback to global and regional teams on market, industry
                                            and competitors' developments. In addition, he has strong
                                            skills in building and maintaining relationships with key
                                            contacts at potential clients, consulting companies and
                                            partners to access new opportunities.
                                        </p>

                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/shankar.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Wg Cdr R Shankar (Retd)</strong>
                                            </p>
                                            <p className="team-item-title">Specialist - Operations</p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Wg Cdr R Shankar (Retd) is a retired
                                            mechanical/aeronautical engineer who has worked as a
                                            technical officer in the Indian Air Force. He has
                                            extensive knowledge and experience in maintaining fighter
                                            aircraft, operating transport and helicopters, as well as
                                            other support machinery. He has held important positions
                                            such as leading a maintenance center and a quality center,
                                            and was responsible for staff hiring and the
                                            indigenization of numerous aircraft support vehicles.
                                            After leaving the IAF, he gained experience in a variety
                                            of fields, including academic operations in a large
                                            institution where he was in charge of educating and
                                            guiding students preparing for competitive exams and
                                            leading the implementation of internet communication
                                            technology in more than 150 government first-grade
                                            colleges to introduce the latest technological
                                            advancements in learning and teaching methodologies to
                                            students.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/ram.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Ram Kulkarni</strong>
                                            </p>
                                            <p className="team-item-title">
                                                Specialist - Solutions Enablement and Engagements
                                            </p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Ram Kulkarni is a Senior Electronics and Communications
                                            Engineering specialist with over 20 years of diverse
                                            experience. He is known for his drive and ability to
                                            deliver business process excellence through exceptional
                                            relationship management and the ability to influence
                                            others (P2I) to support growth. He also has a decade-long,
                                            highly successful entrepreneurial history in consumer
                                            electronics and IT.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/rajesh.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Rajesh Kandoi</strong>
                                            </p>
                                            <p className="team-item-title">Chief Finance Officer</p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Rajesh Kandoi is a finance and accounting professional
                                            with over 25 years of experience in the infrastructure
                                            sector. He holds a B.Com degree and is skilled in
                                            providing guidance on strategy, policies, processes, and
                                            financial controls, ensuring the integrity of financial
                                            results and optimizing financial resources. He has
                                            experience in guiding business relations with external
                                            stakeholders such as audit firms, shareholders, investors
                                            and investment institutions. He is responsible for
                                            overseeing an organization's financial systems, leading
                                            financing, treasury, and merger and acquisition
                                            activities, and has expertise in general management.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item">
                                    <div className="team-item-click-area">
                                        <p>
                                            <img
                                                className="team-item-photo"
                                                src="images/priya-sb.webp"
                                                alt=""
                                            />
                                        </p>
                                        <div className="team-item-brief">
                                            <p className="team-item-name">
                                                <strong>Priya SB</strong>
                                            </p>
                                            <p className="team-item-title">Chief People Officer</p>
                                            <div className="team-item-arrow">
                                                <i className="fal fa-angle-down"></i>
                                            </div>
                                            <div className="team-item-brief-arrow"></div>
                                        </div>
                                    </div>
                                    <div className="team-item-description">
                                        <p>
                                            Priya S B is an experienced Human Resources professional
                                            with a Postgraduate Diploma in IRPM and a Master's degree
                                            in Industrial Psychology. She has over 15 years of
                                            experience in various areas of Human Resources including
                                            Recruitment, Managing and administering HR Policies,
                                            Processes, and systems. She has experience in directing
                                            and implementing the HR portfolio which includes
                                            compensation design, personnel relations, employee
                                            retention, benefits, and services. She also provides
                                            assistance in identifying, evaluating, and resolving human
                                            relations and work performance problems within the
                                            organization. She has a strong ability to build a positive
                                            relationship with all employees.
                                        </p>
                                        <div className="team-item-description-close">
                                            <i className="fal fa-times-circle"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item"></div>
                                <div className="team-item"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <br />
            <br />
            <br />
        </>
    );
};

export default Team;
