import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RequestBanner = () => {

    const API_PATH = 'https://www.pumpacademy.in/mail.php';

    const [message, setMessage] = useState({
        sent: '',
        mess: ''
    });


    const initialValues = {
        name: '',
        lname: '',
        email: '',
        company: '',
        phone: '',
        companyname: '',
        address: '',
    };


    const validationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required('Name is required')
                .min(4, 'Name must be at least 3 characters'),
            email: Yup.string()
                .required('Email is required')
                .email('Email is invalid'),
            company: Yup.string()
                .required('Company name is required'),
            phone: Yup.number()
                .required('A phone number is required')
                .min(10),
        });
    }

    const handleSubmit = (data, { resetForm }) => {

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                'Content-Type': 'application/json'
            },
            data: data
        })
            .then(result => {
                setMessage({
                    sent: result.data.sent,
                    mess: result.data.message
                })
                toast.success("Thankyou for submitting enquiry!!");
                resetForm();
            })
            .catch(error => setMessage(error.sent));
    }

    return (
        <>
            <div className="requestbanner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                            <div className="leftrequest">
                                <img className="img-fluid" src="images/quote.webp" />
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <div className="requestform" id="demo">
                                <h2>Request Demo</h2>
                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                    <Form>
                                        <div className="formdemo">

                                            <div className="req-quote">
                                                <div className="req-field">
                                                    <Field name="name" type="text" className="form-control" placeholder="First Name" />
                                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                                </div>

                                                <div className="req-field">
                                                    <Field name="lname" type="text" className="form-control" placeholder="Last Name" />
                                                    <ErrorMessage name="lname" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="req-quote">
                                                <div className="req-field">
                                                    <Field name="email" type="email" className="form-control" placeholder="Email Address" />
                                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                                </div>

                                                <div className="req-field">
                                                    <Field name="company" type="text" className="form-control" placeholder="Company Name" />
                                                    <ErrorMessage name="company" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div className="req-quote w-100">
                                                <div className="req-field w-100">
                                                    <Field name="phone" type="text" className="form-control" placeholder="Phone" />
                                                    <ErrorMessage name="phone" component="div" className="text-danger" />
                                                </div>
                                            </div>

                                            <div id="msg-req" className="req-field">
                                                <Field name="address" type="text" className="form-control" placeholder="Company Address" />
                                            </div>

                                            <button type="submit">Request Demo</button>
                                        </div>
                                    </Form>
                                </Formik>

                                <ToastContainer theme="colored" position="top-right" autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClickrtl={false} pauseOnFocusLoss draggable pauseOnHover />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="say">
                <div className="container">
                    <div className="sayhlo">
                        <img src="images/pumpnet-logo.webp" />
                        <h3>A Deep Learning Based Integrated Pumping Station <br />Perfromance Management Solution (IPMS).</h3>
                    </div>

                    <div className="lesswork">
                        <ul>
                            <li>
                                <img src="images/less.webp" />
                                <h4>Less work for you</h4>
                            </li>
                            <li>
                                <img src="images/supericon.webp" />
                                <h4>Super flexible</h4>
                            </li>
                            <li>
                                <img src="images/everyone.webp" />
                                <h4>Everyone saves</h4>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </>
    )
}

export default RequestBanner;